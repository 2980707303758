import '../App.css';
import { useContext } from 'react';
import { HypoSemContext } from './hypoSemContext';



export function HypoSummary(props) {
  const { gpaHoursSum, qualityPointsSum } = useContext(HypoSemContext);

  return (
      <div className="hypoSummary">
            <p class="summary">Credit Hours: {gpaHoursSum}</p>
            <p class="summary">Quality Points: {qualityPointsSum}</p>
            <p class="summary">Term GPA: {(qualityPointsSum / gpaHoursSum).toFixed(2)}</p>
      </div>
  );
}

export default HypoSummary;