import '../App.css';
import { GPAProvider } from './gpacontext';
import { HypoCourse } from './hypoCourse.js'
import { HypoSemProvider } from './hypoSemContext.js';
import { useContext } from 'react';
import { HypoSummary } from './hypoSummary.js';
import Paper from '@mui/material/Paper';


function SidePanel() {

  return (
    <GPAProvider>
      <HypoSemProvider>
        <Paper style={{ 
              textAlign: 'center',
              position: 'relative',
              minHeight: "150vh",
              display: 'flex',
              zIndex: "2000"
              }}  >
          <div className="sidePanel">
            <p>Hypothetical Semester</p>
            <HypoCourse id='0'/>
            <HypoCourse id='1'/>
            <HypoCourse id='2'/>
            <HypoCourse id='3'/>
            <HypoCourse id='4'/>
            <HypoCourse id='5'/>
            <HypoSummary />
          </div>
        </Paper>
      </HypoSemProvider>
    </GPAProvider>
  );
}


export default SidePanel;