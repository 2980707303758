import React from "react";
import '../App.css';

function InfoPanel() {
    return (
        <div class="infoPanel">
            <h3><b>What is this?</b></h3>
            <p>This is a GPA calculator and planner. It includes a comprehensive plan for a standard 4 year course of study. </p>
            <br></br>
            <h3><b>How does it work?</b></h3>
            <p>For the planning tool, choose the year you began your studies, enter your transfer GPA hours and quality points, and enter that same information for every semester you took courses.</p>
            <br></br>
            <h3><b>Where do I find these?</b></h3>
            <p>These numbers can be found on your unofficial transcript. They should be clearly stated after every semester of classes.</p>
            <br></br>

        </div>
    )

}

export default InfoPanel;