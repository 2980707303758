import { createContext, useState } from "react";

export const HypoSemContext = createContext();

export const HypoSemProvider = ({children}) => {
    const [gpaHours, setgpaHours] = useState(Array(6).fill(0));
    const [qualityPoints, setqualityPoints] = useState(Array(6).fill(0));
    const [gpaHoursSum, setgpaHoursSum] = useState(0);
    const [qualityPointsSum, setqualityPointsSum] = useState(0);


    /*const handleGPAHoursChange = (value,id) => {
        let qualSum = 0;
        let gpaHoursSum = 0;
    
        for (let i = 0; i <= id; i++) {
            qualSum += qualityPoints[i];
            gpaHoursSum += gpaHours[i];
        }
        
        
        let replace = gpaHoursSum !== 0 ? qualSum / gpaHoursSum : 0;
        console.log(`${gpaHoursSum} / ${qualSum} = ${replace}`); 
    
        setgpaHours(prevValues => {
            const newValues = [...prevValues];
            newValues[id] = value;
            return newValues;
        });
    };*/
    

    const gpaHoursChange = (newValue, id) => {
        setgpaHours(prevValues =>{
            const newValues = [...prevValues];
            newValues[id] = newValue;
            return newValues;
        });
    }

    const qualityPointsChange = (newValue, id) => {
        setqualityPoints(prevValues =>{
            const newValues = [...prevValues];
            newValues[id] = newValue;
            return newValues;
        })
    }

    const calculateSums = () => {
        var tempQualityPointsSum = 0;
        var tempgpaHoursSum = 0;
        for (let i = 0; i < 6; i++) {
            tempQualityPointsSum = tempQualityPointsSum + qualityPoints[i] * gpaHours[i];
            tempgpaHoursSum += gpaHours[i];
        }

        setgpaHoursSum(tempgpaHoursSum);
        setqualityPointsSum(tempQualityPointsSum);
    }


    return (
        <HypoSemContext.Provider value={{
            gpaHours,
            qualityPoints,
            gpaHoursChange,
            qualityPointsChange,
            calculateSums,
            gpaHoursSum,
            qualityPointsSum
        }}>
            {children}
        </HypoSemContext.Provider>
    )
}