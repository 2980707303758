import '../App.css';
import { GPAProvider } from './gpacontext';
import { useContext } from 'react';
import { useState } from 'react';
import { HypoSemContext } from './hypoSemContext';
import { useEffect } from 'react';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';


export function HypoCourse(props) {
  const { gpaHours, qualityPoints, gpaHoursChange, qualityPointsChange, gpahoursSum, qualityPointsSum, calculateSums } = useContext(HypoSemContext);

  const [hypoSem, setHypoSem] = useState({
    gpaHours: 0.0,
    qualityPoints: 0.0,
  });

  useEffect(() => {
    gpaHoursChange(props.id);
    qualityPointsChange(props.id);
    calculateSums();
  }, [hypoSem.gpaHours, hypoSem.qualityPoints]);

  const updateHours = (event) => {
    const gpaHours = parseFloat(event.target.value) || 0.0;
    setHypoSem(prevState => ({...prevState, gpaHours}));
    gpaHoursChange(gpaHours, props.id);
    calculateSums();
  };

  const updateQualPoints = (event) => {
    const qualityPoints = parseFloat(event.target.value) || 0.0;
    setHypoSem(prevState => ({...prevState, qualityPoints
    }));
    qualityPointsChange(qualityPoints, props.id);
    calculateSums();
    
  };

  return (
    <GPAProvider>
      <div className="hypoCourse">
        <TextField className="courseName" size="small"  label="Course Name" sx={{ width: "30%" }}></TextField>
        <TextField size="small" type="number" label="Credits" min='0' max='6' onChange={updateHours} sx={{ width: "30%" }}/>
        <Select size="small" onChange={updateQualPoints} sx={{ width: "15%" }} >
            <MenuItem value={4.00}>A+</MenuItem>
            <MenuItem value={4}>A </MenuItem>
            <MenuItem value={3.67}>A-</MenuItem>
            <MenuItem value={3.33}>B+</MenuItem>
            <MenuItem value={3.00}>B </MenuItem>
            <MenuItem value={2.67}>B-</MenuItem>
            <MenuItem value={2.33}>C+</MenuItem>
            <MenuItem value={2}>C </MenuItem>
            <MenuItem value={1.67}>C-</MenuItem>
            <MenuItem value={1.33}>D+</MenuItem>
            <MenuItem value={1}>D </MenuItem>
            <MenuItem value={.67}>D-</MenuItem>
            <MenuItem value={0}>F </MenuItem>
        </Select>
      </div>
    </GPAProvider>
  );
}




export default HypoCourse;