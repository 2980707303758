import React from 'react';
import './App.css';
import MainPanel from './CalculatorPanel/mainPanel';
import SidePanel from './CalculatorPanel/sidePanel';
import InfoPanel from './CalculatorPanel/infoPanel';
import Grid from '@mui/material/Unstable_Grid2/';
import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import InfoPanel2 from './CalculatorPanel/infopanel2';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.primary,
  position: 'relative',
  minHeight: "100%",
  display: 'flex',
  zIndex: "700",
  overflow: 'hidden',
  [theme.breakpoints.up('xs')]: {
    paddingBottom: theme.spacing(0),
  },
  [theme.breakpoints.up('sm')]: {
    paddingBottom: theme.spacing(0),
  },
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(0),
  },
  [theme.breakpoints.up('lg')]: {
    paddingBottom: theme.spacing(0),
  },
  [theme.breakpoints.up('xl')]: {
    paddingBottom: 0,
  },
}));

const SideItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.primary,
  position: 'relative',
  minHeight: "100%",
  display: 'flex',
  zIndex: "600",
  overflow: 'hidden'
}));

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 500 }}
      role="presentation"
      //onClick={toggleDrawer(anchor, false)}
      //onKeyDown={toggleDrawer(anchor, false)}
    >
      <SidePanel />
    </Box>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <header className="App-header">
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9187050822014009" 
        crossorigin="anonymous"></script>
        </header>
        <body>
        <div>
                  {['right'].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Fab 
                        variant="extended" 
                        style={{ 
                          position: 'fixed', 
                          bottom: 20, 
                          right: 20 
                        }} 
                        onClick={toggleDrawer(anchor, true)}
                      >
                        Semester Simulator
                      </Fab>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        style={{ 
                          zIndex: "1000"
                        }} 
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
          <Grid container spacing={(0)}>
            <Grid xs={0} sm={0} md={0} lg={0} xl={2}> 
              <SideItem>
                <InfoPanel />
              </SideItem>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={8}> 
              <Item>
                <MainPanel />
              </Item>
            </Grid>
            <Grid xs={0} sm={0} md={0} lg={0} xl={2}>
              <SideItem>
                <InfoPanel2></InfoPanel2>
              </SideItem>
            </Grid>
          </Grid>
        </body>
      </div>
    </ThemeProvider>
  );
}

export default App;
