import { useState, useEffect, useContext } from 'react';
import { GPAContext } from './gpacontext';
import Semester from './semester';
import '../App.css';
import { TextField } from '@mui/material';

function Row(props) {
  const { instiGPA, overallGPA, handleoverallGPAChange, handleinstiGPAChange, gpaHoursChange, qualityPointsChange } = useContext(GPAContext);

  const [SemGPA, setSemGPA] = useState({
    gpaHours: 0.0,
    qualityPoints: 0.0,
    termGPA: 0.0,
    institGPA: 0.0,
    overallGPA: 0.0
  });

  useEffect(() => {
    const termGPA = SemGPA.gpaHours !== 0 ? SemGPA.qualityPoints / SemGPA.gpaHours : 0.0;
    setSemGPA(prevState => ({ ...prevState, termGPA, institGPA: termGPA}));
    handleinstiGPAChange(props.sem);
    handleoverallGPAChange(props.sem);
  }, [SemGPA.gpaHours, SemGPA.qualityPoints]);

  const updateHours = (event) => {
    const gpaHours = parseFloat(event.target.value) || 0.0;
    setSemGPA(prevState => ({...prevState, gpaHours}));
    gpaHoursChange(gpaHours, props.sem);
  };

  const updateQualPoints = (event) => {
    const qualityPoints = parseFloat(event.target.value) || 0.0;
    setSemGPA(prevState => ({...prevState,qualityPoints
    }));
    qualityPointsChange(qualityPoints, props.sem);
  };

  var displayTerm = SemGPA.termGPA.toFixed(2);
  var displayInst = instiGPA[props.sem].toFixed(2);
  var displayOverall = overallGPA[props.sem].toFixed(2);

  return (
    <div className="row">
      <tr>
        <td>
          <Semester season={props.season} year={props.year} />
        </td>
        <td>
          <TextField id="GPAHours" label="GPA Hours" size='small' onChange={updateHours} />
        </td>
        <td>
          <TextField id="QualityPoints" label="Quality Points" size="small" onChange={updateQualPoints} />
        </td>
        <td>
          <TextField size="small" label="Term GPA" readOnly value={displayTerm} />
        </td>
        <td>
          <TextField size="small" label="Institutional GPA" readOnly value={displayInst} />
        </td>
        <td>
          <TextField size="small" label="Overall GPA" value={displayOverall} readOnly />
        </td>
      </tr>
    </div>
  );
}

export default Row;
