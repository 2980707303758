import React from "react";
import '../App.css';

function InfoPanel2() {
    return (
        <div class="infoPanel">
            <h3>What's the Semester Simulator Tool?</h3>
            <p>This tool is used to plan a current a future semester's GPA Hours, Quality Points, and GPA using given hypothetical final grades in each course. These numbers can then be used within the planner to see how this hypothetical performance will affect your GPA.</p>
            <br></br>
            <h3>I don't wanna send my GPA over the internet, why would I use this?</h3>
            <p>Everything grade-related entered onto this site exists only within your computer's web browser. Nothing is saved.</p>

        </div>
    )

}

export default InfoPanel2;