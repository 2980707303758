import { useState, useEffect, useContext } from 'react';
import { GPAContext } from './gpacontext';
import Semester from './semester';
import '../App.css';
import { TextField } from '@mui/material';

function Transfer(props) {
  const { instiGPA, handleinstiGPAChange, handleoverallGPAChange, gpaHoursChange, qualityPointsChange, transferqualityPointsChange, transfergpaHoursChange } = useContext(GPAContext);

  const [SemGPA, setSemGPA] = useState({
    gpaHours: 0.0,
    qualityPoints: 0.0,
    termGPA: 0.0,
    institGPA: 0.0,
    overallGPA: 0.0
  });

  useEffect(() => {
    const termGPA = SemGPA.gpaHours !== 0 ? SemGPA.qualityPoints / SemGPA.gpaHours : 0.0;
    setSemGPA(prevState => ({ ...prevState, termGPA, institGPA: termGPA}));
    for (let i = 0; i <= 11; i++) {
      handleoverallGPAChange(i);
    }
  }, [SemGPA.gpaHours, SemGPA.qualityPoints, handleoverallGPAChange]);

  const updateHours = (event) => {
    const gpaHours = parseFloat(event.target.value) || 0.0;
    setSemGPA(prevState => ({...prevState, gpaHours}));
    transfergpaHoursChange(gpaHours);
  };

  const updateQualPoints = (event) => {
    const qualityPoints = parseFloat(event.target.value) || 0.0;
    setSemGPA(prevState => ({...prevState,qualityPoints
    }));
    transferqualityPointsChange(qualityPoints, props.sem);
  };

  var displayTerm = SemGPA.termGPA.toFixed(2);

  return (
    <div className="row">
      <br></br>
      <TextField id="GPAHours" size="small" label="Transfer GPA Hours" onChange={updateHours} />
      <TextField id="QualityPoints" size="small" label="Quality Points" onChange={updateQualPoints} />
      <TextField readOnly size="small" value={displayTerm} label="Transfer GPA"/>
    </div>
  );
}

export default Transfer;
