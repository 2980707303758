import { createContext, useState, useEffect } from "react";

export const GPAContext = createContext();

export const GPAProvider = ({children}) => {
    const [gpaHours, setgpaHours] = useState(Array(12).fill(0.0));
    const [qualityPoints, setqualityPoints] = useState(Array(12).fill(0.0));
    const [transfergpaHours, settransfergpaHours] = useState(0);
    const [transferqualityPoints, settransferqualityPoints] = useState(0);
    const [instiGPA, setinstiGPA] = useState(Array(12).fill(0.0));
    const [overallGPA, setoverallGPA] = useState(Array(12).fill(0.0));
   
    useEffect(() => {
        
    }, []);

    const transfergpaHoursChange = (newValue) => {
        settransfergpaHours(newValue);
    }

    const transferqualityPointsChange = (newValue) => {
        settransferqualityPoints(newValue);
    }


    const handleinstiGPAChange = (id) => {
        let qualSum = 0;
        let gpaHoursSum = 0;
    
        for (let i = 0; i <= id; i++) {
            qualSum += qualityPoints[i];
            gpaHoursSum += gpaHours[i];
        }
    
        
        let replace = gpaHoursSum !== 0 ? qualSum / gpaHoursSum : 0;
        console.log(`${gpaHoursSum} / ${qualSum} = ${replace}`);
    
        setinstiGPA(prevValues => {
            const newValues = [...prevValues];
            newValues[id] = replace;
            return newValues;
        });
    };
    

    const handleoverallGPAChange = (id) => {
        let qualSum = transferqualityPoints;
        let gpaHoursSum = transfergpaHours;
    
        for (let i = 0; i <= id; i++) {
            qualSum += qualityPoints[i];
            gpaHoursSum += gpaHours[i];
        }
    
        
        let replace = gpaHoursSum !== 0 ? qualSum / gpaHoursSum : 0;
        console.log(`${gpaHoursSum} / ${qualSum} = ${replace}`);
    
        setoverallGPA(prevValues => {
            const newValues = [...prevValues];
            newValues[id] = replace;
            return newValues;
        });

    };

    const gpaHoursChange = (newValue, id) => {
        setgpaHours(prevValues =>{
            const newValues = [...prevValues];
            newValues[id] = newValue;
            return newValues;
        });
        handleinstiGPAChange(id);
    }

    const qualityPointsChange = (newValue, id) => {
        setqualityPoints(prevValues =>{
            const newValues = [...prevValues];
            newValues[id] = newValue;
            return newValues;
        });
        handleinstiGPAChange(id);
    }


    return (
        <GPAContext.Provider value={{
            transfergpaHours,
            transferqualityPoints,
            gpaHours,
            qualityPoints,
            instiGPA,
            overallGPA,
            transfergpaHoursChange,
            transferqualityPointsChange,
            gpaHoursChange,
            qualityPointsChange,
            handleinstiGPAChange,
            handleoverallGPAChange
        }}>
            {children}
        </GPAContext.Provider>
    )
}