import { Typography } from '@mui/material';
import '../App.css';
import { useState } from 'react';
import '@fontsource/roboto/300.css';

function Semester(props) {
    const [semester, setSemester] = useState();
    return (
        <div className="Semester" >
            <p></p>
            <Typography variant="h6" display="block" gutterBottom>
            {props.season} {props.year}
      </Typography>
        </div>
    );
}

export default Semester;